import * as React from "react"

// Import fonts
import '@fontsource/space-grotesk'
import '@fontsource/inter'

import {
  ChakraProvider,
  Flex,
  Text,
  Stack,
} from "@chakra-ui/react"
import { extendTheme  } from "@chakra-ui/react"

const theme = extendTheme({
  colors: {
    brand: {
      red: "#d74034",
    },
    fonts: {
      heading: `'Space Grotesk', sans-serif`,
      body: `'Inter', sans-serif`,
    },
  },
})

export const App = () => (
  <ChakraProvider theme={theme}>
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      backgroundColor="white.200"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
      >
        <Stack fontFamily="Space Grotesk, sans-serif" fontWeight="bold" direction="row" spacing="-2px">
          <Text fontSize="46pt" color="brand.red">UMD</Text>
          <Text fontSize="44pt">.to</Text>
        </Stack>
        <Text>Coming soon.</Text>
      </Stack>
    </Flex>
  </ChakraProvider>
)
